<template>
  <div class="footer">
    <div class="footer__wrapper">
      <Section limiter>
        <Column>
          <Row class="footer__navigation"
            :wrap="!$laptop"
            justify="between">
            <Column class="m-b-md-16 grid-sm-4 grid-md-4 grid-lg-3">
              <span class="footer__navigation-title">{{ $t('footer.menu.about_h.h') }}</span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/municipality`">
                    {{ $t('footer.menu.about_h.about') }}
                  </router-link>
                </li>
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/events`">
                    {{ $t('footer.menu.about_h.events') }}
                  </router-link>
                </li>
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/news`">
                    {{ $t('footer.menu.about_h.news') }}
                  </router-link>
                </li>
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/stories`">
                    {{ $t('footer.menu.about_h.topics') }}
                  </router-link>
                </li>
              </ul>
              <span class="footer__navigation-title">{{ $t('footer.menu.guide_h.h') }}</span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/places`">{{ $t('footer.menu.guide_h.guide') }}
                  </router-link>
                </li>
              </ul>
            </Column>
            <Column class="m-b-md-16 grid-sm-4 grid-md-4 grid-lg-3">
              <span class="footer__navigation-title">{{ $t('footer.menu.tours_h.h') }}</span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/trips`">{{ $t('footer.menu.tours_h.tours') }}
                  </router-link>
                </li>
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/excursions`">
                    {{ $t('footer.menu.tours_h.excursions') }}
                  </router-link>
                </li>
              </ul>
              <span class="footer__navigation-title">{{ $t('footer.menu.playbill_h.h') }}</span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/events`">
                    {{ $t('footer.menu.playbill_h.playbill') }}
                  </router-link>
                </li>
              </ul>
              <span class="footer__navigation-title">
                {{ $t('footer.menu.transport_h.h') }}
              </span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <router-link v-if="$showIfDefaultCity"
                    class="footer__navigation-link"
                    :to="`${$locale.base}/transport-map`">
                    {{ $t('footer.menu.transport_h.transport_map') }}
                  </router-link>
                </li>
                <li class="footer__navigation-li">
                  <router-link class="footer__navigation-link"
                    :to="`${$locale.base}/transport`">
                    {{ $t('footer.menu.transport_h.h') }}
                  </router-link>
                </li>
              </ul>
            </Column>
            <Column class="m-b-md-16 grid-sm-4 grid-md-4 grid-lg-3">
              <span class="footer__navigation-title">{{ $t('footer.menu.links_h.h') }}</span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <a class="footer__navigation-link"
                    :href="metaFooterSiteLink">{{ $t('footer.menu.links_h.city_administration') }}
                  </a>
                </li>
              </ul>
              <span class="footer__navigation-title">{{ $t('footer.menu.write_us_h.h') }}</span>
              <ul class="footer__navigation-ul">
                <li class="footer__navigation-li">
                  <a class="footer__navigation-link"
                    :href="`mailto:${metaFooterFeedbackLink}`">
                    {{ $t('footer.menu.write_us_h.feedback') }}
                  </a>
                </li>
              </ul>
              <span class="footer__navigation-title">
                {{ $t('footer.menu.download_mobile_app') }}
              </span>
              <img class="footer__qr grid-sm-2 grid-md-1 grid-lg-1"
                :src="require('@/assets/images/qr.jpg')" alt/>
            </Column>
            <Column class="m-b-md-16 grid-sm-4 grid-md-4 grid-lg-3"
              justify="between"
              :align="$laptop ? 'end' : 'start'">
              <Column>
                <span class="footer__navigation-title">
                  {{ $t('footer.menu.social') }}</span>
                <Row :justify="$laptop ? 'end' : 'start'"
                  class="-m-h-12">
                  <div v-for="(network, name) in metaFooterSocialNetworks"
                    :key="name"
                    class="social-network">
                    <a :class="`social-network__icon-${name}
                      social-network__icon-${name}-active`"
                       class="social-network__icon"
                       :href="network.link"
                       target="_blank">
                      <Icon viewport="0 0 23 23"
                        :xlink="`${name}-icon`"/>
                    </a>
                  </div>
                </Row>
              </Column>
              <Column :align="$laptop ? 'end' : 'start'" v-if="$weather && icon">
                <span class="footer__navigation-title">{{ $t('footer.menu.weather') }}</span>
                <Row>
                  <div class="footer__weather weather">
                    <Row align="center"
                      class="weather__wrapper">
                      <img class="weather__icon"
                        :src="icon"/>
                      <Column class="weather__info">
                      <span class="weather__temp">
                        {{temp > 0 ? `${temp} °C` : `- ${temp} °C`}}</span>
                        <span class="weather__wind">
                        {{speed}} m/s
                      </span>
                      </Column>
                    </Row>
                  </div>
                </Row>
              </Column>
            </Column>
          </Row>
          <Divider color="5c5b5a"/>
          <Row class="grid-sm-full grid-md-12 grid-lg-12 footer__command"
            justify="between"
            wrap>
            <Column class="m-b-sm-16 grid-sm-full grid-md-3 grid-lg-3 footer__rir">
              <Row class="grid-sm-4">
                <Icon class="footer__rir-icon"
                  viewport="0 0 42 42"
                  xlink="logotype-rusatom"/>
                <span class="footer__rir-description">{{ $t('footer.menu.logo') }}</span>
              </Row>
            </Column>
            <Column class="m-b-sm-16 grid-sm-full grid-md-3 grid-lg-3">
              <span class="footer__command-title">{{ $t('footer.menu.developers') }}</span>
              <span class="footer__command-description">{{ $t('footer.menu.rusatom') }}</span>
            </Column>
            <Column class="m-b-sm-16 grid-sm-full grid-md-3 grid-lg-3">
              <span class="footer__command-description footer__command-support">
                {{ $t('footer.menu.with_support') }}</span>
            </Column>
            <Column class="grid-sm-full grid-md-3 grid-lg-3">
              <Row class="-m-12"
                :justify="$mobile ? 'start' : 'end'">
                <div class="footer__command-round footer__command-round-grey">
                  <span class="footer__command-age">6+</span>
                </div>
                <div class="footer__command-round footer__command-round-white"
                  v-on:click="handleClick">
                  <Icon class="footer__command-round-arrow"
                    xlink="arrow-slider-next"
                    viewport="0 0 25 18"/>
                </div>
              </Row>
            </Column>
          </Row>
          <Divider color="5c5b5a"/>
          <Row class="footer__trademark">
            <span class="footer__trademark-description">© 2019-2020</span>
          </Row>
        </Column>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    meta() {
      return this?.$meta.find((item) => item.slug === this.$city.slug);
    },
    metaFooterSiteLink() {
      return this?.meta?.footer?.site?.link;
    },
    metaFooterSiteText() {
      return this?.meta?.footer?.site?.text;
    },
    metaFooterFeedbackLink() {
      return this?.meta?.footer?.feedback?.link;
    },
    metaFooterFeedbackText() {
      return this?.meta?.footer?.feedback?.text;
    },
    metaFooterSocialNetworks() {
      return this?.meta?.footer?.socialNetworks;
    },
    icon() {
      return this?.$weather?.data?.weather[0]?.icon
        ? `https://openweathermap.org/img/wn/${this?.$weather?.data?.weather[0]?.icon}@2x.png`
        : '';
    },
    temp() {
      return this?.$weather?.data?.main?.temp;
    },
    speed() {
      return this?.$weather?.data?.wind?.speed;
    },
  },
  methods: {
    handleClick() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
